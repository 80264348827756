import React from "react"
import Seo from "../components/seo"
// https://github.com/FortAwesome/react-fontawesome/issues/234
import "@fortawesome/fontawesome-svg-core/styles.css"
import { cv_english } from "../data/cv"
import "../../static/fonts/fonts.css"
import { StaticImage } from "gatsby-plugin-image"

export default function Cv() {

  let data = cv_english;

  return <>
    <Seo
      title={'CV'}
      description={"Kristoffer Strøm Bergset's CV"}
    />
    <div className="container cv-container font-charter">
      <div className="flex flex-row flex-wrap items-center w-full">
        <div className="p-5 w-full sm:w-auto sm:text-left print:w-auto print:text-left">
          <div className="w-40 h-40 mx-auto">
            <StaticImage
              src="../../static/kristoffer-strom-bergset.jpg"
              style={{borderRadius: '50%'}}
            />
          </div>
        </div>
        <div className="p-5 w-full text-center sm:w-auto sm:text-left print:w-auto print:text-left">
          <h1 className="text-2xl dark:text-white">
            {data.name}<br />
            <span className="text-xl">{data.title}</span>
          </h1>
          <div className="pt-2">
            <p>{data.address}</p>
            <p>{data.phone}</p>
            <p>
              <a href={"mailto:" + data.email}>{data.email}</a>
            </p>
          </div>
        </div>
        {/*<div className="p-5 flex-grow flex flex-row justify-end print-only">*/}
        {/*  <QRCode*/}
        {/*    bgColor="#FFFFFF"*/}
        {/*    fgColor="#000000"*/}
        {/*    level="Q"*/}
        {/*    style={{*/}
        {/*      width: 128,*/}
        {/*    }}*/}
        {/*    value={data.website}*/}
        {/*    className="print-only"*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div className="pl-5 pr-5 pb-5">
        <h2 className="cv-headline">{data.qualificationsTitle}</h2>
        <ul className="pt-5 pl-5 list-disc">
          {data.qualifications.map((e, i) => {
            return <li key={i}>{e}</li>
          })}
        </ul>
        <h2 className="cv-headline">{data.experienceTitle}</h2>
        {data.experiences.map((e, i) => {
          return <div key={i} className="py-5">
            <div className="md:flex md:flex-row md:flex-wrap md:justify-between print:flex print:flex-row print:flex-wrap print:justify-between">
              <div>
                {e.href ?
                  <a href={e.href} target="_blank" rel="noreferrer">
                    <h3 className="text-lg">{e.company}</h3>
                  </a>
                  :
                  <h3 className="text-lg">{e.company}</h3>
                }
                <span className="font-medium">{e.title}</span>
              </div>
              <div>
                <i>{e.from}{e.to && ' - ' + e.to}</i>
              </div>
            </div>
            <div className="pt-5">
              <p>
                {e.description}
              </p>
              {e.descriptionBulletsIntro && <p><br />{e.descriptionBulletsIntro}</p>}
              {e.descriptionBullets && <ul className="pt-5 pl-5 list-disc">
                {e.descriptionBullets.map((bullet, i) => {
                  if (bullet.href) {
                    return <li key={i}>
                      <a href={bullet.href} target="_blank" rel="noreferrer">
                        {bullet.text}
                      </a>
                    </li>
                  } else {
                    return <li key={i}>{bullet.text}</li>
                  }
                })}
              </ul>}
            </div>
            {i !== (data.experiences.length - 1) && <hr className="cv-divider" />}
          </div>
        })}
        <h2 className="cv-headline">{data.educationTitle}</h2>
        {data.education.map((e, i) => {
          return <div key={i} className="py-3">
            <h3>{e.school}</h3>
            <span className="font-medium">{e.degree}</span><br />
            {e.to ? <i>{e.from} - {e.to}</i> : <i>{e.from}</i>}
          </div>
        })}
        <h2 className="cv-headline">Technologies</h2>
        <div className="w-full text-center"><a href="https://stackshare.io/krisberg">https://stackshare.io/krisberg</a></div>
      </div>
    </div>
  </>;
}