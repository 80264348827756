export const cv_english = {
  name: 'Kristoffer Strøm Bergset',
  title: 'Software Engineer',
  address: 'Hornneskletten 4, 6809 Førde, Norway',
  email: 'kristoffer@bergset.no',
  phone: '+47 934 03 725',
  website: 'https://kristoffer.bergset.no',
  qualificationsTitle: 'Key Qualifications',
  qualifications: [
    'Backend development of web applications (5+ years)',
    'Frontend development of web applications and websites (7+ years)',
    'Relational databases (5+ years)',
    'Linux servers (7+ years)',
    'Amazon Web Services (4+ years)',
    'Teaching and mentoring (5+ years)'
  ],
  experienceTitle: 'Experience',
  experiences: [
    {
      company: 'Bergset AS',
      href: 'https://www.bergset.no',
      title: 'Software Developer / CTO',
      from: '2016',
      to: 'Present',
      description: 'Freelance software development. Full-time employed since June 2021.',
      descriptionBulletsIntro: 'Some of my cases include:',
      descriptionBullets: [
        {
          text: 'Continued development and maintenance of Munikum for Sødermann AS',
          href: 'https://www.bergset.no/case/sodermann-as',
        },
        {
          text: 'Full Stack development for Romvesen AS',
          href: 'https://www.bergset.no/case/romvesen-as',
        },
      ]
    },
    {
      company: 'Munikum AS / Sødermann AS',
      href: 'https://www.munikum.no',
      title: 'Full Stack Developer',
      from: 'May 2016',
      to: 'May 2021',
      description: 'Development, deployment and maintenance of the SaaS product Munikum.',
      descriptionBullets: [
        {
          text: 'Developed API web application (Grails, PostgreSQL, GraphQL, REST)',
        },
        {
          text: 'Developed frontend application (React, Redux, Typescript)',
        },
        {
          text: 'Developed micro-services in Rust and Javascript',
        },
        {
          text: "Wrote unit and integration tests for API web application",
        },
        {
          text: "Set up continuous integration and continuous deployment with git and Jenkins",
        },
        {
          text: "Set up and maintained the company's services in Amazon Web Services (Kubernetes, Cloudfront, Jenkins, Wordpress)",
        },
        {
          text: "Mentored frontend developer apprentice",
        },
      ]
    },
    {
      company: 'Eid High School',
      href: 'https://www.eid.vgs.no/',
      title: 'Teacher',
      from: 'September 2013',
      to: 'July 2016',
      description: 'Computer electronics and IT teacher. Taught the students programming (Arduino, Javascript, C++, Java), computer networking, firewalls, servers and electronics.',
    },
    {
      company: 'NTNU (Formerly Ålesund University College)',
      title: 'Student assistant / Software Developer',
      from: 'August 2012',
      to: 'May 2013',
      description: 'Student assistant in the course "Web Technology" (HTML, CSS, PHP, Javascript, Java) for first year computer engineering students. ' +
        'Developed an Android mobile application and Java EE web application for an e-learning project.',
    },
    {
      company: 'InMedia AS',
      title: 'Apprentice / Web Developer',
      from: 'August 2007',
      to: 'July 2009',
      description: 'Developed websites (HTML, CSS, PHP, ASP, Joomla, EWAT) ' +
        "and maintained the company's web and email servers (Linux and Windows). " +
        "Employed February 2009 after completed apprenticeship.",
    }
  ],
  educationTitle: 'Education',
  education: [
    {
      school: 'NTNU (Formerly Ålesund University College)',
      degree: "Bachelor's degree, Computer Engineering",
      from: '2009',
      to: '2013',
    },
    {
      school: 'Eid High School',
      degree: 'Certificate Computer Electronics',
      from: '2009'
    },
  ],
  skillsTitle: 'Skills',
  skills: 'Proficient in blablabla',
}